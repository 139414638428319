<template>
  <div class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center">
    <div v-if="!isLoadingLoader" class="vx-col sm:m-0 m-4 sm:w-1/2 md:w-1/2 lg:w-2/4 xl:w-1/4">
      <vx-card class="text-center">
        <template v-if="meetingId && meeting">
          <div class="vx-card__title mb-4">
            <h4 class="text-center">
              <template v-if="!isExpiredMeeting">{{ $t('info.manageAppointment') }}</template>
              <template v-else>{{ $t('info.yourAppointmentExpired') }}</template>
            </h4>
          </div>

          <template v-if="isFetchedMeeting">
            <div class="mb-3 text-lg">
              {{ meetingDate }}
              <br />
              {{ $t('info.approxDuration') }} {{ meeting.duration }} {{ $t('info.minutes') }}
            </div>

            <div class="mb-3 text-lg">{{ $t('info.yourAdvisor') }}: {{ agent.displayName }}</div>

            <div class="flex justify-center">
              <vs-chip v-if="meeting.status === 'confirmed'" color="success">
                <vs-avatar icon="check" color="success" />

                {{ $t('info.appointmentConfirmed') }}
              </vs-chip>

              <vs-chip v-else-if="meeting.status === 'canceled'" color="danger">
                <vs-avatar icon="check" color="danger" />

                {{ $t('info.appointmentCanceled') }}
              </vs-chip>
            </div>
          </template>

          <div v-if="!isExpiredMeeting && meeting.status === 'confirmed'" class="flex justify-center mt-6">
            <vs-button class="flex items-center mr-5 px-4 text-md" @click="openDialog">
              <div class="flex items-center">
                <feather-icon class="mr-2" icon="EditIcon" svgClasses="h-4 w-4" />

                {{ $t('info.change') }}
              </div>
            </vs-button>

            <vs-button class="flex items-center px-4 text-md" color="danger" @click="isOpenedConfirmCancelingMeetingDialog = true">
              <div class="flex items-center">
                <feather-icon class="mr-2" icon="DeleteIcon" svgClasses="h-4 w-4" />

                {{ $t('info.cancel') }}
              </div>
            </vs-button>
          </div>
        </template>

        <template v-else>
          <div class="vx-card__title">
            <h4 class="text-center">{{ $t('info.meetingNotFound') }}</h4>
          </div>
        </template>
      </vx-card>
    </div>

    <vs-popup :title="$t('info.chooseADate')" :active.sync="isOpenedChangeMeetingDialog">
      <div v-if="isLoadingDialog" class="text-center" v-html="`${$t('info.loading')}...`" />

      <vs-collapse v-else-if="filteredSelectedWeekDays.length" type="margin">
        <vs-collapse-item v-for="(weekday, index) in filteredSelectedWeekDays" icon-arrow="arrow_drop_down" :key="index">
          <div slot="header">
            <p>{{ weekday.m.date() }} {{ $t('system.months.short.' + weekday.m.format('MMM').toLowerCase()) }}</p>
            <p>{{ $t('system.weekdays.short.' + weekday.m.format('ddd').toLowerCase()) }}</p>
          </div>

          <div class="text-center">
            <vs-button v-for="(slot, i) in weekday.times" class="mr-3 mb-3" style="width: 95px" :key="i" @click="selectSlot(slot)">
              {{ slot.timestamp.format('HH:mm') }}
            </vs-button>
          </div>
        </vs-collapse-item>
      </vs-collapse>

      <div v-else>{{ $t('info.noAvailableDates') }}</div>
    </vs-popup>

    <vs-popup :title="$t('info.cancelAppointment')" :active.sync="isOpenedConfirmCancelingMeetingDialog">
      <div>
        {{ $t('info.confirmationOfCancelling') }}

        <vs-textarea name="cancel_reason" key="cancel_reason" class="mt-3" height="80px" :label="$t('info.reasonForCancellation')" v-model="cancelReason" />
      </div>

      <vs-button class="flex mx-auto px-4 text-md" color="danger" @click="confirmCancelingMeeting">
        {{ $t('info.cancelAppointment') }}
      </vs-button>
    </vs-popup>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import moment from 'moment'
import _ from 'underscore'

const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc') // dependent on utc plugin
const timezone = require('dayjs/plugin/timezone')
const relativeTime = require('dayjs/plugin/relativeTime')
const localizedFormat = require('dayjs/plugin/localizedFormat')
const isBetween = require('dayjs/plugin/isBetween')
const customParseFormat = require('dayjs/plugin/customParseFormat')

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)
dayjs.extend(localizedFormat)
dayjs.extend(isBetween)
dayjs.extend(customParseFormat)

export default {
  data: () => ({
    currentWeekNumber: 0,
    selectedWeekDays: [],

    isLoading: false,
    isLoadingDialog: false,

    timeslotsAgentsByWeekNumber: [],
    meetingId: null,
    isOpenedChangeMeetingDialog: false,
    isOpenedConfirmCancelingMeetingDialog: false,
    slots: [],
    groups: [],
    meeting: null,
    appointment: {},
    duration: 30,
    leadTime: 120,
    agent: {},
    meetingDate: null,
    bookedAgentId: null,
    date: '',
    cancelReason: ''
  }),

  computed: {
    ...mapState(['AppActiveUser']),

    moment() {
      return moment()
    },

    isLoadingLoader() {
      if (this.isLoading) {
        this.$vs.loading()
      } else {
        this.$vs.loading.close()
      }

      return this.isLoading
    },

    isFetchedMeeting() {
      return !!Object.keys(this.meeting).length
    },

    filteredSelectedWeekDays() {
      return this.selectedWeekDays.filter((weekday) => weekday && weekday.times.length)
    },

    isExpiredMeeting() {
      const diffByIntervalInMinutes = () => {
        const duration = moment.duration(moment(new Date(this.meeting.date)).diff(moment(new Date())))
        const diffMins = duration.asMinutes() + parseInt(this.meeting.duration)

        return diffMins >= 0
      }

      return this.meeting.date && !diffByIntervalInMinutes()
    }
  },

  created() {
    this.isLoading = true
    this.currentWeekNumber = moment().week()

    this.getWeekDaysOfWeek(this.currentWeekNumber)
  },

  async mounted() {
    await this.getMeeting()

    if (this.meeting) {
      await this.fetchAppointmentData()
      await this.getAgent()
    }

    this.setLanguage()

    this.isLoading = false
  },

  methods: {
    setLanguage() {
      if (this.AppActiveUser && this.AppActiveUser.language === 'de') {
        this.$i18n.locale = this.AppActiveUser.language
      } else if (!this.AppActiveUser && this.meeting && this.meeting.locale === 'de') {
        this.$i18n.locale = this.meeting.locale
      }
    },

    async getMeeting() {
      this.meetingId = this.$route.params.id

      const meetingRef = await this.$db.collection('meetings').doc(this.meetingId).get()
      const meetingData = meetingRef.data()

      if (meetingData) {
        this.meeting = await meetingRef.data()
        this.meetingDate = moment(this.meeting.date).locale(this.$i18n.locale).format('LLLL')
      }
    },

    async fetchAppointmentData() {
      if (!this.meeting.selectedAppointment) {
        return
      }

      const appointmentRef = await this.$db
        .collection('company')
        .doc(this.meeting.companyId)
        .collection('appointments')
        .doc(this.meeting.selectedAppointment)
        .get()

      const appointmentData = appointmentRef.data()

      this.appointment = appointmentData
      this.duration = appointmentData.duration
      this.leadTime = appointmentData.leadTime
    },

    async getAgent() {
      if (this.meeting.agentId) {
        const agentRef = await this.$db.collection('users').doc(this.meeting.agentId).get()

        this.agent = await agentRef.data()
      }
    },

    async openDialog() {
      this.isLoadingDialog = true
      this.isOpenedChangeMeetingDialog = true

      try {
        await this.getCalenderItems()

        this.addWeekDayAndTimes()
      } catch (error) {
        /* eslint-disable no-console */
        console.error(error)
      }

      this.isLoadingDialog = false
    },

    async confirmCancelingMeeting() {
      this.isLoading = true

      const meetingRef = this.$db.collection('meetings').doc(this.meetingId)

      const data = {
        status: 'canceled',
        cancelDate: new Date().toISOString()
      }

      if (this.cancelReason) {
        data.cancelReason = this.cancelReason
      }

      await meetingRef.set(data, {
        merge: true
      })

      await this.getMeeting()
      await this.getAgent()

      this.isOpenedConfirmCancelingMeetingDialog = false
      this.isLoading = false

      this.$vs.notify({
        time: 8800,
        title: this.$i18n.t('vue.success'),
        text: this.$i18n.t('vue.changesSavedSuccessfully'),
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'success'
      })
    },

    async selectSlot(slot) {
      if (dayjs().diff(slot.timestamp, 'minutes') > 0) {
        return
      }

      this.isLoading = true

      const meetingDate = slot.timestamp
      const agentId = slot.users[Math.floor(Math.random() * slot.users.length)]

      const meetingRef = await this.$db.collection('meetings').doc(this.meetingId)

      const data = {
        agentId,
        modifiedDate: new Date().getTime(),
        date: new Date(meetingDate).getTime()
      }

      await meetingRef.set(data, {
        merge: true
      })

      await this.getMeeting()
      await this.getAgent()

      this.isOpenedChangeMeetingDialog = false
      this.isLoading = false

      this.$vs.notify({
        time: 8800,
        title: this.$i18n.t('vue.success'),
        text: this.$i18n.t('vue.changesSavedSuccessfully'),
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'success'
      })
    },

    async getCalenderItems() {
      const endDate = new Date()
      const startDate = new Date()

      endDate.setMonth(endDate.getMonth() + 1)

      const data = {
        visitorId: this.meeting.visitorId,
        companyId: this.meeting.companyId,
        dialogId: this.meeting.dialogId,
        abTestId: this.meeting.abTestId,
        duration: this.meeting.duration,
        timezone: dayjs.tz.guess(),
        selectedAppointment: this.meeting.selectedAppointment,
        start: dayjs(startDate).format(),
        end: dayjs(endDate).format()
      }

      let response = null

      try {
        const calendarGetAvailableAgentsTimeslots = this.$functions.httpsCallable('calendarGetAvailableAgentsTimeslots')

        response = await calendarGetAvailableAgentsTimeslots(data)
      } catch {
        return
      }

      if (!response) {
        return
      }

      const modifiedAbilityData = this.modifyAbilityData(response.data)

      const timeslotsAgentsArray = {
        data: modifiedAbilityData
      }

      /* saving this data for caching & faster retrieval */
      const ts = this.timeslotsAgentsByWeekNumber.filter((x) => x.weekNumber === this.currentWeekNumber)

      if (ts.length > 0) {
        this.timeslotsAgentsByWeekNumber.map(() => {
          return {
            weekNumber: this.currentWeekNumber,
            timeslotsAgentsArray
          }
        })
      } else {
        this.timeslotsAgentsByWeekNumber.push({
          weekNumber: this.currentWeekNumber,
          timeslotsAgentsArray
        })
      }

      await this.calculateTimeSlotsAgents(timeslotsAgentsArray)
    },

    async calculateTimeSlotsAgents(timeslotsAgentsArray) {
      let timeslots = []
      // round starting minutes up to nearest 15 (12 --> 15, 17 --> 30)
      // note that 59 will round up to 60, and moment.js handles that correctly

      /* default notice period if not saved is 2hrs / 120 minutes */
      const noticePeriodInMinutes = Number(this.leadTime)
      const duration = Number(this.duration)

      for (let i = 0; i < timeslotsAgentsArray.data.length; i++) {
        const agent = timeslotsAgentsArray.data[i]
        const selectedTimezone = agent && agent.timezone && agent.timezone.name ? agent.timezone.name : dayjs.tz.guess()

        for (let n = 0; n < agent.windows.length; n++) {
          const slot = agent.windows[n]
          const startDateWithLeadTime = dayjs(slot.start)

          let start = startDateWithLeadTime
          const end = dayjs(slot.end).add(-duration, 'minute')
          const minuteRounded = (Math.round(start.get('minute') / duration) * duration) % 60

          start = start.minute(minuteRounded)

          let current = start.clone()

          if (start.valueOf() < startDateWithLeadTime.valueOf()) {
            current = current.add(duration, 'minute')
          }

          while (current.valueOf() <= end.valueOf()) {
            const match = _.find(timeslots, (timeslot) => {
              return timeslot.dateFormated === current.format('YYYY-MM-DD HH:mm')
            })

            /* Get day of week of current day. Week should start from Monday. i.e Monday should have zero and Sunday = 6*/
            const dayOfWeek = current.day() - 1 < 0 ? 6 : current.day() - 1

            if (match && agent.availableTimeSlots.length > 0 && agent.availableTimeSlots[dayOfWeek].enabled) {
              /* Add to timeslots array only of agent has availability */
              // const format24hr = 'HH:mm'
              /* Loop through the agents available slots for the week day */
              for (let t = 0; t < agent.availableTimeSlots[dayOfWeek].times.length; t++) {
                const beforeHHmm = agent.availableTimeSlots[dayOfWeek].times[t].start.split(':')
                const afterHHmm = agent.availableTimeSlots[dayOfWeek].times[t].end.split(':')
                const time = current.clone()

                let beforeTime = dayjs(time.format('YYYY-MM-DD')).set('hour', beforeHHmm[0]).set('minute', beforeHHmm[1]),
                  afterTime = dayjs(time.format('YYYY-MM-DD')).set('hour', afterHHmm[0]).set('minute', afterHHmm[1])
                beforeTime = dayjs.tz(beforeTime.format('YYYY-MM-DD HH:mm'), selectedTimezone)
                afterTime = dayjs.tz(afterTime.format('YYYY-MM-DD HH:mm'), selectedTimezone)
                /* add to timeslots only if current time is in between the available time slots */

                if (
                  (time.isBetween(beforeTime, afterTime) || time.isSame(beforeTime)) &&
                  (dayjs(startDateWithLeadTime).isBefore(current) || dayjs(startDateWithLeadTime).isSame(current))
                ) {
                  // const timeslot = {
                  //   users: [agent.userId],
                  //   dateFormated: time.format('YYYY-MM-DD HH:mm'),
                  //   timestamp: time.clone(),
                  //   agentTimezone: selectedTimezone,
                  //   visitorTimezone: dayjs.tz.guess()
                  // }
                  // timeslots.push(timeslot)
                  match.users.push(agent.userId)
                  break
                }
              }
            } else {
              /* Check if todays events has atleast notice period time avaiable before it starts */
              /* Ex: if the notice period in minutes is 180, then make sure that if there are any events today, it has atleast 180 minutes left to start  */
              if (dayjs().format('YYYY-MM-DD') === current.format('YYYY-MM-DD')) {
                const minimumTime = dayjs().add(noticePeriodInMinutes, 'minute')
                const differenceTime = current.diff(minimumTime, 'minute')

                if (differenceTime <= 0) {
                  current = current.add(duration, 'minute')

                  continue
                }
              }

              /* Get day of week of current day. Week should start from Monday. i.e Monday should have zero and Sunday = 6*/
              const dayOfWeek = current.day() - 1 < 0 ? 6 : current.day() - 1

              /* Add to timeslots array only of agent has availability */
              if (agent.availableTimeSlots !== undefined && agent.availableTimeSlots.length > 0 && agent.availableTimeSlots[dayOfWeek].enabled) {
                //const format24hr = 'HH:mm'

                /* Loop through the agents available slots for the week day */
                for (let t = 0; t < agent.availableTimeSlots[dayOfWeek].times.length; t++) {
                  const beforeHHmm = agent.availableTimeSlots[dayOfWeek].times[t].start.split(':')
                  const afterHHmm = agent.availableTimeSlots[dayOfWeek].times[t].end.split(':')

                  const time = current.clone()
                  let beforeTime = dayjs(time.format('YYYY-MM-DD')).set('hour', beforeHHmm[0]).set('minute', beforeHHmm[1]),
                    afterTime = dayjs(time.format('YYYY-MM-DD')).set('hour', afterHHmm[0]).set('minute', afterHHmm[1])

                  beforeTime = dayjs.tz(beforeTime.format('YYYY-MM-DD HH:mm'), selectedTimezone)
                  afterTime = dayjs.tz(afterTime.format('YYYY-MM-DD HH:mm'), selectedTimezone)

                  /* add to timeslots only if current time is in between the available time slots */
                  if (
                    (time.isBetween(beforeTime, afterTime) || time.isSame(beforeTime)) &&
                    (dayjs(startDateWithLeadTime).isBefore(current) || dayjs(startDateWithLeadTime).isSame(current))
                  ) {
                    const timeslot = {
                      users: [agent.userId],
                      dateFormated: time.format('YYYY-MM-DD HH:mm'),
                      timestamp: time.clone(),
                      agentTimezone: selectedTimezone,
                      visitorTimezone: dayjs.tz.guess()
                    }

                    timeslots.push(timeslot)

                    break
                  }
                }
              }
            }

            current = current.add(duration, 'minute')
          }
        }
      }

      const occurrenceDay = (timeslot) => {
        return dayjs(timeslot.timestamp).startOf('day').format()
      }

      const groupToDay = (group, day) => {
        return {
          day: dayjs(day).format('YYYY-MM-DD'),
          times: group,
          timestamp: day,
          active: false
        }
      }

      timeslots = timeslots.sort((a, b) => a.timestamp.unix() - b.timestamp.unix())

      this.groups = _.chain(timeslots).groupBy(occurrenceDay).map(groupToDay).sortBy('timestamp').value()

      this.$forceUpdate()

      this.isLoadingDialog = true
    },

    async asyncForEach(array, callback) {
      for (let index = 0; index < array.length; index++) {
        return callback(array[index], index, array)
      }

      return true
    },

    getWeekDaysOfWeek(weekNumber) {
      this.currentWeekNumber = weekNumber
      this.selectedWeekDays = []

      const endDate = new Date()
      const startDate = new Date()

      endDate.setMonth(endDate.getMonth() + 1)

      const differenceInDays = moment(endDate).diff(moment(startDate), 'days')

      for (let i = 0; i <= differenceInDays; i++) {
        this.selectedWeekDays.push({
          m: moment(startDate).add(i, 'days'),
          times: []
        })
      }
    },

    addWeekDayAndTimes() {
      this.selectedWeekDays.filter((weekday) => {
        const day = weekday.m.format('YYYY-MM-DD')
        weekday.day = day
        /* check if day exists in groups */
        const group = this.groups.filter((group) => group.day === day)
        if (group && group.length > 0) {
          weekday.times = group[0].times
        }
      })
    },

    modifyAbilityData(data) {
      if (!data) return []

      const modifiedData = data.map((item) => {
        const modifiedWindows = item.windows.map((dateValues) => {
          const copyDateValues = { ...dateValues }

          Object.keys(copyDateValues).forEach((key) => {
            copyDateValues[key] = this.modifyDate(copyDateValues[key])
          })

          return copyDateValues
        })

        const copyItem = { ...item }

        copyItem.windows = modifiedWindows

        return copyItem
      })

      return modifiedData
    },

    modifyDate(date) {
      if (!date) return null

      const utcDate = dayjs(date).format()
      const utcDateString = new Date(utcDate).toString()
      const localDate = dayjs.utc(new Date(utcDateString)).local().format()

      return localDate
    }
  }
}
</script>
