var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "h-screen flex w-full bg-img vx-row no-gutter items-center justify-center",
    },
    [
      !_vm.isLoadingLoader
        ? _c(
            "div",
            {
              staticClass:
                "vx-col sm:m-0 m-4 sm:w-1/2 md:w-1/2 lg:w-2/4 xl:w-1/4",
            },
            [
              _c(
                "vx-card",
                { staticClass: "text-center" },
                [
                  _vm.meetingId && _vm.meeting
                    ? [
                        _c("div", { staticClass: "vx-card__title mb-4" }, [
                          _c(
                            "h4",
                            { staticClass: "text-center" },
                            [
                              !_vm.isExpiredMeeting
                                ? [
                                    _vm._v(
                                      _vm._s(_vm.$t("info.manageAppointment"))
                                    ),
                                  ]
                                : [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("info.yourAppointmentExpired")
                                      )
                                    ),
                                  ],
                            ],
                            2
                          ),
                        ]),
                        _vm.isFetchedMeeting
                          ? [
                              _c("div", { staticClass: "mb-3 text-lg" }, [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.meetingDate) +
                                    "\n            "
                                ),
                                _c("br"),
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.$t("info.approxDuration")) +
                                    " " +
                                    _vm._s(_vm.meeting.duration) +
                                    " " +
                                    _vm._s(_vm.$t("info.minutes")) +
                                    "\n          "
                                ),
                              ]),
                              _c("div", { staticClass: "mb-3 text-lg" }, [
                                _vm._v(
                                  _vm._s(_vm.$t("info.yourAdvisor")) +
                                    ": " +
                                    _vm._s(_vm.agent.displayName)
                                ),
                              ]),
                              _c(
                                "div",
                                { staticClass: "flex justify-center" },
                                [
                                  _vm.meeting.status === "confirmed"
                                    ? _c(
                                        "vs-chip",
                                        { attrs: { color: "success" } },
                                        [
                                          _c("vs-avatar", {
                                            attrs: {
                                              icon: "check",
                                              color: "success",
                                            },
                                          }),
                                          _vm._v(
                                            "\n\n              " +
                                              _vm._s(
                                                _vm.$t(
                                                  "info.appointmentConfirmed"
                                                )
                                              ) +
                                              "\n            "
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm.meeting.status === "canceled"
                                    ? _c(
                                        "vs-chip",
                                        { attrs: { color: "danger" } },
                                        [
                                          _c("vs-avatar", {
                                            attrs: {
                                              icon: "check",
                                              color: "danger",
                                            },
                                          }),
                                          _vm._v(
                                            "\n\n              " +
                                              _vm._s(
                                                _vm.$t(
                                                  "info.appointmentCanceled"
                                                )
                                              ) +
                                              "\n            "
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          : _vm._e(),
                        !_vm.isExpiredMeeting &&
                        _vm.meeting.status === "confirmed"
                          ? _c(
                              "div",
                              { staticClass: "flex justify-center mt-6" },
                              [
                                _c(
                                  "vs-button",
                                  {
                                    staticClass:
                                      "flex items-center mr-5 px-4 text-md",
                                    on: { click: _vm.openDialog },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "flex items-center" },
                                      [
                                        _c("feather-icon", {
                                          staticClass: "mr-2",
                                          attrs: {
                                            icon: "EditIcon",
                                            svgClasses: "h-4 w-4",
                                          },
                                        }),
                                        _vm._v(
                                          "\n\n              " +
                                            _vm._s(_vm.$t("info.change")) +
                                            "\n            "
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                                _c(
                                  "vs-button",
                                  {
                                    staticClass:
                                      "flex items-center px-4 text-md",
                                    attrs: { color: "danger" },
                                    on: {
                                      click: function ($event) {
                                        _vm.isOpenedConfirmCancelingMeetingDialog = true
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "flex items-center" },
                                      [
                                        _c("feather-icon", {
                                          staticClass: "mr-2",
                                          attrs: {
                                            icon: "DeleteIcon",
                                            svgClasses: "h-4 w-4",
                                          },
                                        }),
                                        _vm._v(
                                          "\n\n              " +
                                            _vm._s(_vm.$t("info.cancel")) +
                                            "\n            "
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    : [
                        _c("div", { staticClass: "vx-card__title" }, [
                          _c("h4", { staticClass: "text-center" }, [
                            _vm._v(_vm._s(_vm.$t("info.meetingNotFound"))),
                          ]),
                        ]),
                      ],
                ],
                2
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "vs-popup",
        {
          attrs: {
            title: _vm.$t("info.chooseADate"),
            active: _vm.isOpenedChangeMeetingDialog,
          },
          on: {
            "update:active": function ($event) {
              _vm.isOpenedChangeMeetingDialog = $event
            },
          },
        },
        [
          _vm.isLoadingDialog
            ? _c("div", {
                staticClass: "text-center",
                domProps: { innerHTML: _vm._s(_vm.$t("info.loading") + "...") },
              })
            : _vm.filteredSelectedWeekDays.length
            ? _c(
                "vs-collapse",
                { attrs: { type: "margin" } },
                _vm._l(_vm.filteredSelectedWeekDays, function (weekday, index) {
                  return _c(
                    "vs-collapse-item",
                    { key: index, attrs: { "icon-arrow": "arrow_drop_down" } },
                    [
                      _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                        _c("p", [
                          _vm._v(
                            _vm._s(weekday.m.date()) +
                              " " +
                              _vm._s(
                                _vm.$t(
                                  "system.months.short." +
                                    weekday.m.format("MMM").toLowerCase()
                                )
                              )
                          ),
                        ]),
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "system.weekdays.short." +
                                  weekday.m.format("ddd").toLowerCase()
                              )
                            )
                          ),
                        ]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "text-center" },
                        _vm._l(weekday.times, function (slot, i) {
                          return _c(
                            "vs-button",
                            {
                              key: i,
                              staticClass: "mr-3 mb-3",
                              staticStyle: { width: "95px" },
                              on: {
                                click: function ($event) {
                                  return _vm.selectSlot(slot)
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(slot.timestamp.format("HH:mm")) +
                                  "\n          "
                              ),
                            ]
                          )
                        }),
                        1
                      ),
                    ]
                  )
                }),
                1
              )
            : _c("div", [_vm._v(_vm._s(_vm.$t("info.noAvailableDates")))]),
        ],
        1
      ),
      _c(
        "vs-popup",
        {
          attrs: {
            title: _vm.$t("info.cancelAppointment"),
            active: _vm.isOpenedConfirmCancelingMeetingDialog,
          },
          on: {
            "update:active": function ($event) {
              _vm.isOpenedConfirmCancelingMeetingDialog = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("info.confirmationOfCancelling")) +
                  "\n\n      "
              ),
              _c("vs-textarea", {
                key: "cancel_reason",
                staticClass: "mt-3",
                attrs: {
                  name: "cancel_reason",
                  height: "80px",
                  label: _vm.$t("info.reasonForCancellation"),
                },
                model: {
                  value: _vm.cancelReason,
                  callback: function ($$v) {
                    _vm.cancelReason = $$v
                  },
                  expression: "cancelReason",
                },
              }),
            ],
            1
          ),
          _c(
            "vs-button",
            {
              staticClass: "flex mx-auto px-4 text-md",
              attrs: { color: "danger" },
              on: { click: _vm.confirmCancelingMeeting },
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.$t("info.cancelAppointment")) + "\n    "
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }